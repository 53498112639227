import React, {useEffect, useState} from 'react'
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import {Basic, Facebook, Twitter} from '../components/SEO';
import { ArticleList, CategoryHeader, track } from '@cg-squad/ui-components';
import 'react-virtualized/styles.css';
import {adDisplayDelay, delayMultiplicationFactor, setStreamData} from "../utils/articleUtil";
import mixpanel from "../constants/mixpanel";
import {isMobile} from "react-device-detect";
import {subCategoryPageAds} from "../utils/adUnits";
import {metadata} from '../../config';
import {addTimeout} from '../utils/timeManager';
import SeeMore from "../components/buttons/SeeMore";
import Helmet from "react-helmet";

const SubCategoryPage = ({path, data, pageContext, location}) => {
    const allArticles = [...data.pillarArticles.nodes];
    data.allDatoCmsArticle.nodes.forEach(article => {
        if (!data.pillarArticles.nodes.find(a => a.id === article.id)) {
            allArticles.push(article);
        }
    })

    const seo = {
        title: pageContext.title,
        description: `Discover all of our ${pageContext.title} articles, written by our knowledgeable team of writers.`
    }
    const url = `${metadata.url}/${pageContext.categorySlug}/${pageContext.slug}`;

    const [displayedSubCategoryArticles, setDisplayedSubCategoryArticles] = useState(allArticles.slice(0, 10));

    const seeMore = () => {
        setDisplayedSubCategoryArticles(allArticles.slice(0, displayedSubCategoryArticles.length + 10));
    }

    useEffect(() => {
        //calculateHeights();
        setStreamData({
            category: undefined,
            subCategory: pageContext.title,
            article: undefined,
            author: undefined
        })
        track(mixpanel.MIXPANEL_PAGE_VIEW.SUB_CATEGORY);
        addTimeout(subCategoryPageAds, adDisplayDelay() * delayMultiplicationFactor(isMobile), [
            {key: 'category', value: pageContext.categorySlug},
            {key: 'subcategory', value: pageContext.slug}
        ]);
    }, []);

    return (
        <Layout relativePath={path?.replace('/', '')} path={location.pathname}>
            <Basic seo={seo}/>
            <Twitter seo={seo} url={url}/>
            <Facebook seo={seo} url={url}/>
            <Helmet>
                <link rel="canonical" href={url}/>
            </Helmet>
            <div className="wrapper" data-datocms-noindex>
                <div className={"w-full lg:flex font-arial"}>
                    <div className={""}>
                        <CategoryHeader category={{title: pageContext.title, slug: pageContext.slug}} isSub={true}/>
                        <div className="ad-container my-4 min-h-[306px] lg:min-h-[276px]" data-datocms-noindex>
                            <div className="advertisement-text">Advertisement</div>
                            <div id="pt_subcategory_incontent1"/>
                        </div>
                        <ArticleList articles={displayedSubCategoryArticles}/>
                        {displayedSubCategoryArticles.length < allArticles.length > 0 && <SeeMore onSeeMoreClick={seeMore}/>}
                    </div>
                    {/*<PopularPosts affiliateCategory={pageContext.slug} className={"flex-1"}/>*/}
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ArticlesSubCategoryWise($slug: String) {
        pillarArticles: allDatoCmsArticle(filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "pt"}}}, isCategoryPinned: {eq: true}}, sort: {publishedAt: DESC}) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
        allDatoCmsArticle(
            filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "pt"}}}}, 
            sort: {publishedAt: DESC}) {
            nodes {
                heading
                excerpt
                excerptNode {
                  childMarkdownRemark {
                    html
                  }
                }
                slug
                author {
                    name
                    slug
                }
                category {
                    title
                    slug
                    treeParent {
                        slug
                    }
                    position
                    website {
                        name
                    }
                }
                cover {
                    url
                    gatsbyImageData(
                        layout: FULL_WIDTH,
                        placeholder: BLURRED,
                        forceBlurhash: false,
                        imgixParams: { auto: "compress", fit: "crop", w: "450", q: 40, ar: "3:2" }
                    )
                    width
                    height
                }
                publishedAt
            }
            totalCount
        }
    }
`
export default SubCategoryPage
